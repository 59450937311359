@mixin keyframes-animation($name) {
	@-webkit-keyframes #{$name} {
		@content;
	}

	@keyframes #{$name} {
		@content;
	}
}

@mixin prefix-property($name, $value) {
	-webkit-#{$name}: $value;
	#{$name}: $value;
}

@include keyframes-animation(loading-spinner) {
	0% {
		@include prefix-property(transform, rotate(0deg));
	}

	20% {
		@include prefix-property(transform, rotate(360deg));
	}

	100% {
		@include prefix-property(transform, rotate(360deg));
	}
}

.homeContainer {
	width: 100vw;
	height: 100vh;
	overflow: hidden;
	position: relative;
	margin: 0;
	padding: 0 !important;

	.bgLayer {
		background-repeat: no-repeat;
		background-size: 100% 100%;
		position: relative;
		width: 100%;
		height: 100%;

		&.animated {
			position: absolute;
			width: 210%;
			height: 120%;
			top: -10%;
			left: -55%;
			animation: loading-spinner 12s ease-in-out 5s infinite;
		}
	}

	.bgVideoWrapper {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
	}

	.language-flag-strip {
		position: absolute;
		bottom: 7%;
		left: 8%;
	}

	.buttonGraphic {
		border-radius: 4rem !important;
		position: absolute;
		text-transform: upperCase;
		text-align: center;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 2rem;
		top: calc(50% - 4rem);
		width: 50%;
		left: 25%;
	}

}

.menuLoading {
	.MuiBackdrop-root {
		background-color: rgba(#000000, 0.1);
	}

	.MuiPaper-root {
		background-color: transparent
	}
}