.MuiDialog-container {
    [role=dialog]:not(.MuiDialog-paperFullScreen) {
        width: calc(100% - 4rem);
        max-height: calc(100% - 4rem);
        margin: 2rem;
        max-width: 37.5rem;

        .MuiDialogTitle-root {
            padding: 1rem 1.5rem;
        }

        .MuiDialogContent-root {
            padding: 1.25rem 1.5rem;
        }

        .MuiDialogActions-root {
            padding: 0.5rem;
        }


        &.MuiDialog-paperWidthSm {
            max-width: 37.5rem;
        }

        &.MuiDialog-paperWidthMd {
            max-width: 56.25rem;
        }

        &.MuiDialog-paperWidthLg {
            max-width: 75rem;
        }

        &.MuiDialog-paperWidthXl {
            max-width: 96rem;
        }
    }
}