.checkout-choose-container.MuiContainer-root {
	width: 100vw;
	height: 100vh;
	overflow: hidden;
	background-repeat: no-repeat;
	background-size: 100% 100%;
	padding: 0;
	position: relative;

	.language-flag-strip {
		position: absolute;
		bottom: 7%;
		left: 8%;
		z-index: 3;
	}

	h3 {
		font-size: 2.8rem;
		line-height: 1.4;
	}

	.bgLayer {
		background-repeat: no-repeat;
		background-size: 100% 100%;
		position: relative;
		width: 100%;
		height: 100%;
		z-index: 2;
	}

	.icon-image {
		height: 20%;
		width: 50%;
		left: 7%;
		top: 10%;
		position: absolute;
		z-index: 3;
	}

	.btnGraphic {
		display: block;
		position: absolute;
		top: 44%;
		left: 7%;
		width: 86%;
		height: 18%;
		z-index: 3;

		.cta {
			text-transform: upperCase;
			text-align: center;
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 1rem 2rem;
			width: 50%;
			height: 100%;
		}

		&.takeAway {
			top: 65.5%;

			.cta {
				margin-left: 50%;
			}
		}

		&.underBg {
			z-index: 1;
			transform: translateY(0.25rem);
		}
	}
}